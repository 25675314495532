export enum Url {
    // Token Free
    login = '/test-center/login',
    getProfile = '/getProfile',
    signup = '/user/signup',

    // patient login
    patientLogin= '/user/mobile-login',
    getAppointment = '/appointment/list',

    // test center
    upcomingAppoinment = "/appointment/upcoming",
    sendOTP = "/user/send-otp",
    verifyOtp = "/test-center/verify-user",
    allReason = '/test/reason/all',
    allTestType = '/test-type/all',
    testTypeMCQ = '/test-type/mcq',
    bookAppoinment = '/appointment/book',
    appoinmentDetail = '/appointment/details?appointmentId=',
    verifyInsurence = '/appointment/verify-insurance',
    cancelAppoinment = '/appointment/changeStatus',
    testResultStatus = '/test-center/changeResultStatus',
    updatePhlebotomy = '/test-center/updatePhlebotomy',
    dasboardData = '/test-center/dashboard',
    centreProfile = '/test-center/profile',
    logoutCentre = '/user/logout',
    ​listAppointment = '/appointment/list',
    uploadReport = '/test-center/uploadResult',
    testResultList = '/appointment/testResult/list',
    sendTestkit = '/appointment/testKitAction',
    // website
    contactUs = "/user/contactUs",
    cityAll = "/city/all",
    centerByCity = "/test-center/all?city=",
    patientProfile = "/user/profile",
    patientForgotPasswordMobile = "/user/forgotPassword/mobile",
    patientForgotPasswordEmail = "/user/forgotPassword/email",
    patientForgotPasswordMobileOTP = "/user/forgotPassword/verifyOTP",
    patientForgotPasswordEmailOTP = "/user/forgotPassword/verifyOTPEmail",
    sendPasswordForgotPassword = "/user/setNewPassword",
    sendPasswordForgotPasswordEmail = "/user/setNewPasswordEmail",
    patientChangePassword = '/user/changePassword',
    getNotifications = '/test-center/notifications',
    readNotifications= '/test-center/notification',
    testKitAction = '/appointment/testKitAction',
    verifyTestKitQRCode = '/facility/verifyTestKitQRCode/',
    checkTestKitQRCode = '/facility/checkTestKitQRCode/',
    readAllNotifications = '/test-center/notifications/readAll',
  }
  