import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Url } from 'app/core/enums';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TestCenterService {

    constructor(
        private http: HttpClient
    ) { }

    httpoptions() {

        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': '*/*'
            })
        }

    }


    getTitle(): string {
        return 'Test11';
    }


    // upcoming Appoinment
    upcomingAppoinment(data, fromDate, upcommingDays) {
        return this.http.get(`${environment.apiBaseUrl}${Url.upcomingAppoinment}?fromDate=${fromDate}&upcomingDays=${upcommingDays}`, this.httpoptions());
    }

    // patient appointment
    getAllAppointment(type, name, patientId, upcommingDays) {
        return this.http.get(`${environment.apiBaseUrl}${Url.getAppointment}?type=${type}&name=${name}&patientId=${patientId}`, this.httpoptions());
    }

    checkedIn(type, name, patientId, appointmentId, appointNo?) {
        return this.http.get(`${environment.apiBaseUrl}${Url.testResultList}?type=${type}&name=${name}&patientId=${patientId}&appointmentId=${appointmentId}&appointNo=${appointNo}`, this.httpoptions());
    }

    // upload report
    uploadReport(data) {
        return this.http.post(`${environment.apiBaseUrl}${Url.uploadReport}`, data, this.httpoptions());
    }

    appoinmentDetail(data) {
        return this.http.get(`${environment.apiBaseUrl}${Url.appoinmentDetail}${data}`, this.httpoptions());
    }

    // verify mobile number
    sendOtpOnMobile(data) {
        return this.http.post(`${environment.apiBaseUrl}${Url.sendOTP}`, data, this.httpoptions());
    }
    verifyOtpNo(data) {
        return this.http.post(`${environment.apiBaseUrl}${Url.verifyOtp}`, data, this.httpoptions());
    }

    allReason() {
        return this.http.get(`${environment.apiBaseUrl}${Url.allReason}`, this.httpoptions());
    }

    allTestType() {
        return this.http.get(`${environment.apiBaseUrl}${Url.allTestType}`, this.httpoptions());
    }
    allMCQ(data) {
        return this.http.get(`${environment.apiBaseUrl}${Url.testTypeMCQ}?testTypeId=${data?.testTypeId}`, this.httpoptions());
    }

    createAppoinment(data) {
        return this.http.post(`${environment.apiBaseUrl}${Url.bookAppoinment}`, data, this.httpoptions());
    }
    verifyInsurence(data) {
        return this.http.post(`${environment.apiBaseUrl}${Url.verifyInsurence}`, data, this.httpoptions());
    }

    cancelAppoinment(data) {
        return this.http.post(`${environment.apiBaseUrl}${Url.cancelAppoinment}`, data, this.httpoptions());
    }


    dashboardData() {
        return this.http.get(`${environment.apiBaseUrl}${Url.dasboardData}`, this.httpoptions());
    }

    centreProfile() {
        return this.http.get(`${environment.apiBaseUrl}${Url.centreProfile}`, this.httpoptions());
    }

    logout() {
        return this.http.post(`${environment.apiBaseUrl}${Url.logoutCentre}`, this.httpoptions());
    }

    testResultStatus(status, id) {
        return this.http.post(`${environment.apiBaseUrl}${Url.testResultStatus}?appointmentId=${id}&status=${status}`, this.httpoptions());
    }

    updatePhlebotomy(id, data) {
        return this.http.post(`${environment.apiBaseUrl}${Url.updatePhlebotomy}?appointmentId=${id}`, data, this.httpoptions());
    }

    cityAll() {
        return this.http.get(`${environment.apiBaseUrl}${Url.cityAll}`, this.httpoptions());
    }

    centerByCity(city) {
        return this.http.get(`${environment.apiBaseUrl}${Url.centerByCity}${city}`, this.httpoptions());
    }

    patientProfile() {
        return this.http.get(`${environment.apiBaseUrl}${Url.patientProfile}`, this.httpoptions());
    }

    //forgot password
    sendOtpOnEmailForgotPassword(data) {
        return this.http.post(`${environment.apiBaseUrl}${Url.patientForgotPasswordEmail}`, data, this.httpoptions());
    }
    sendOtpOnEmailForgotPasswordOTP(data) {
        return this.http.post(`${environment.apiBaseUrl}${Url.patientForgotPasswordEmailOTP}`, data, this.httpoptions());
    }
    sendPasswordForgotPasswordEmail(data) {
        return this.http.post(`${environment.apiBaseUrl}${Url.sendPasswordForgotPasswordEmail}`, data, this.httpoptions());
    }

    // patient forgot password
    sendOtpOnMobileForgotPassword(data) {
        return this.http.post(`${environment.apiBaseUrl}${Url.patientForgotPasswordMobile}`, data, this.httpoptions());
    }
    sendOtpOnMobileForgotPasswordOTP(data) {
        return this.http.post(`${environment.apiBaseUrl}${Url.patientForgotPasswordMobileOTP}`, data, this.httpoptions());
    }
    sendPasswordForgotPassword(data) {
        return this.http.post(`${environment.apiBaseUrl}${Url.sendPasswordForgotPassword}`, data, this.httpoptions());
    }
    patientChangePassword(data) {
        return this.http.post(`${environment.apiBaseUrl}${Url.patientChangePassword}`, data, this.httpoptions());
    }


    getTestResultAppointment(data) {
        return this.http.get(`${environment.apiBaseUrl}${Url.testResultList}`, this.httpoptions());
    }

    allTestTypeWithCenter(center) {
        return this.http.get(`${environment.apiBaseUrl}${Url.allTestType}?testCenterId=${center}`, this.httpoptions());
    }

    sendTestkit(appointmentId: string, body: any) {
        return this.http.post(`${environment.apiBaseUrl}${Url.sendTestkit}?appointmentId=${appointmentId}`, body, this.httpoptions());
    }

    getNotifications() {
        return this.http.get(`${environment.apiBaseUrl}${Url.getNotifications}`, this.httpoptions());
    }

    readNotification(body){
        return this.http.patch(`${environment.apiBaseUrl}${Url.readNotifications}`,body, this.httpoptions())
    }

    testKitAction(appointmentId: string, body: {testKitID:string, action: string ,status:boolean}) {
        return this.http.post(`${environment.apiBaseUrl}${Url.testKitAction}?appointmentId=${appointmentId}`,body, this.httpoptions())
    }

    verifyTestKitQRCode(id:string){
        return this.http.get(`${environment.apiBaseUrl}${Url.verifyTestKitQRCode}${id}`, this.httpoptions());
    }

    getTestKitData(id:string) {
        return this.http.get(`${environment.apiBaseUrl}${Url.checkTestKitQRCode}${id}`, this.httpoptions());
    }

    readAllNotifications() {
        return this.http.patch(`${environment.apiBaseUrl}${Url.readAllNotifications}`, this.httpoptions());
    }
}
