import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TestCenterService } from 'app/services/testCenter/test-center.service';
import { timer } from 'rxjs';
import { DatePipe, Location } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth/auth.service';
import { state } from 'app/core/constants/states';
import { Path } from 'app/core/enums';

@Component({
  selector: 'app-patient-signup',
  templateUrl: './patient-signup.component.html',
  styleUrls: ['./patient-signup.component.scss']
})
export class PatientSignupComponent implements OnInit {

  step = 1;

  timeLeft: number = 0;
  interval;
  subscribeTimer: any;

  displayStyle = "none"

  sendOTPForm: FormGroup;
  mobileNo
  verifyOtpForm: FormGroup;
  otpSent = true
  submitted
  loading = false
  tempOtp
  otpNo
  errorMessage = ""
  enabledOTP = false
  continueAppoinment
  successMessage
  footerBtn = false;
  states = state

  DOB_Max_Date = this.datepipe.transform(new Date(new Date().setFullYear(new Date().getFullYear() - 18)), 'dd-MM-YYYY')

  mobile
  securityNoErrorMessage
  paisentDetailForm: FormGroup;
  maxDate = { year: Number(this.DOB_Max_Date.split('-')[2]), month: Number(this.DOB_Max_Date.split('-')[1]), day: Number(this.DOB_Max_Date.split('-')[0]) }

  constructor(
    private testCenter: TestCenterService,
    private fb: FormBuilder,
    private location: Location,
    private router: Router,
    private authService: AuthService,
    public datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.sendOTPForm = this.fb.group({
      contact: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.maxLength(10)]],
    });

    this.verifyOtpForm = this.fb.group({
      otp: ['', [Validators.required]],
    })

    this.paisentDetailForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      dob: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      nationalId: ['', [Validators.required, Validators.pattern("^[0-9]*$"),
      Validators.minLength(4), Validators.maxLength(4)]],
    });
  }

  onSubmitMobile() {
    // this.otpSent = true;
    this.submitted = true;
    if (this.sendOTPForm.invalid) {

      return false;
    }
    if (this.sendOTPForm.valid) {
      this.loading = true
      let data = {
        mobile: this.sendOTPForm.value.contact
      }

      // this.loaderServicer.isLoading.next(true);
      this.testCenter.sendOtpOnMobile(data)
        .subscribe((res: any) => {
          if (res?.status === "OK") {
            this.mobileNo = this.sendOTPForm.value.contact
            this.tempOtp = res?.data?.otp
            console.log("otp", res?.data?.otp)
            // this.formData();
            this.startTimer();
            localStorage.setItem('mobile', this.sendOTPForm.value.contact)
            if (res?.message === "OTP Send Successfully") {
              this.enabledOTP = true
            }
            console.log("res", res);
            this.loading = false
          } else {
            this.errorMessage = res?.message
            this.loading = false
          }
        }, (error) => {
          // if(error?.error?.error[0]?.param === "password"){
          //   this.errorMessage = "Invalid Password"
          // }else{
          //   this.errorMessage = error?.message
          // }
          this.loading = false
          if (error?.message === "User already exist") {
            this.openPopup()
          }
          console.log("res", error);
        })
    }
  }

  // timer
  oberserableTimer() {
    const source = timer(1000, 2000);
    const abc = source.subscribe(val => {
      console.log(val, '-');
      this.subscribeTimer = this.timeLeft - val;
    });
  }

  startTimer() {
    this.timeLeft = 60;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        // this.timeLeft = 60;
        this.pauseTimer()
      }
    }, 1000)
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  openPopup() {
    this.displayStyle = "block";
  }
  closePopup() {
    this.displayStyle = "none";
    this.router.navigate(['/centre']);
  }

  onSubmitOTP() {
    // this.otpSent = true;
    this.loading = true
    this.submitted = true;
    if (this.otpNo) {
      let data = {
        mobile: this.sendOTPForm.value.contact,
        otp: this.otpNo
      }

      // this.loaderServicer.isLoading.next(true);
      this.testCenter.verifyOtpNo(data)
        .subscribe((res: any) => {
          if (res?.status === "OK") {
            console.log("res", res);
            this.errorMessage = ""
            this.successMessage = res?.message
            this.loading = false
            this.footerBtn = true
            this.pauseTimer();
            this.step = 2;
            this.submitted = false;
          } else {
            console.log("res", res);
            this.errorMessage = res?.message
            // this.triggerModal(Content)
            this.loading = false

          }
        }, (error) => {
          // if(error?.error?.error[0]?.param === "password"){
          //   this.errorMessage = "Invalid Password"
          // }else{
          //   this.errorMessage = error?.message
          // }
          this.successMessage = ''
          this.errorMessage = error?.message
          this.loading = false
          console.log("res", error);
        })
    }
  }


  onOtpChange(e) {
    console.log("otp", e)
    this.otpNo = e
  }

  get g() {
    return this.sendOTPForm.controls
  }

  get f() {
    return this.paisentDetailForm.controls
  }

  signup() {

    this.submitted = true;
    if (this.paisentDetailForm.invalid || this.f.nationalId.value.toString().length != 4) {
      return false;
    } else {

      if (this.paisentDetailForm.valid) {
        console.log("dates", this.paisentDetailForm.value.dob)
        let dobCustom = `${this.paisentDetailForm.value.dob.day}-${this.paisentDetailForm.value.dob.month}-${this.paisentDetailForm.value.dob.year}`
        this.paisentDetailForm.value.dob
        this.loading = true
        let data = {
          ...this.paisentDetailForm.value,
          confirmPassword: undefined,
          mobile: this.sendOTPForm.value.contact,
          nationalId: (this.paisentDetailForm.value.nationalId).toString(),
          dob: dobCustom
        }
        this.authService.signup(data)
          .subscribe((res: any) => {
            this.location.back();
            if (res?.status === "OK") {
              console.log("success")
              console.log("res", res);
              this.loading = false
            } else {
              console.log("not success", res);
              this.loading = false
            }
          }, (error) => {
            if (error?.statusCode === 400 || error?.statusCode === 422) {
              this.errorMessage = error?.message
              this.openPopup()
              console.log("error msg", error?.message);
            } else {
              // this.errorMessage = error?.message
            }
            this.loading = false
            console.log("error", error);
            console.log("error msg", error?.message);
          })
      }
    }
  }

  goToback() {
    this.location.back();
  }

}
